import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    *{
        margin:0;
        padding:0;
        box-sizing:border-box;
    }
    h1{
        font-family:'Roboto Mono', monospace;
        font-weight:600;
        font-style:italic;
        padding:1rem;
    }
    p{
        font-family:'Roboto Mono', monospace;
    }
`;

export default GlobalStyle;
