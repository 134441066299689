import styled from "styled-components";
import React from "react";

function App() {
    return (
        <Container>
            <Info>
                <h1>Coming Soon...</h1>
                <p>© 2022 gotashell.com</p>
            </Info>
        </Container>
    );
}

const Container = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Info = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export default App;
